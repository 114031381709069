<ion-header>
  <ion-toolbar>
    <div class="modal-title">
      <h3>Broadcast analytics</h3>
    </div>
    <ion-buttons slot="end">
      <ion-button fill="clear" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div style="height: 32px; margin-top: 8px; margin-left: 1px">
    <span class="tab-nav-link" (click)="getOverviewData()" [ngClass]="{'tab-nav-active': tab === 'overview' }">
      Overview
    </span>
    <span class="tab-nav-link" (click)="getClicksByUrlData()" [ngClass]="{'tab-nav-active': tab === 'clicksByUrl' }">
      Clicks by URL
    </span>
    <span
      *ngIf="outbound.deliveryMethod === 'slack'"
      class="tab-nav-link"
      (click)="tab = 'conversations'"
      [ngClass]="{'tab-nav-active': tab === 'conversations' }"
    >
      Replies & Reactions
    </span>
  </div>
</ion-header>

<ion-content>
  <div *ngIf="tab === 'overview'">
    <ion-grid>
      <app-survey-analytics *ngIf="hasSurvey" [surveyId]="surveyId" [outboundId]="outbound.id"></app-survey-analytics>
      <ion-row>
        <ion-col size="12" *ngIf="hasSurvey">
          <h3 class="column-header">Clicks & Opens</h3>
        </ion-col>
        <ion-col size-md="6" size="12" *ngIf="isEmail">
          <ion-card class="outline-card report-card">
            <div class="report-title">Open rate</div>
            <span class="report-stat">{{openRate ? openRate + '%' : '•••'}}</span>
            <div class="onboarding-progress-bar">
              <div class="meter" [style.width.%]="openRate || 0"></div>
            </div>
            <div class="report-title">{{totalOpens}} opens • {{uniqueOpens}} unique</div>
          </ion-card>
        </ion-col>
        <ion-col size-md="6" size="12">
          <ion-card class="outline-card report-card">
            <div class="report-title">Click rate</div>
            <span class="report-stat">{{clickRate ? clickRate + '%' : '•••'}}</span>
            <div class="onboarding-progress-bar">
              <div class="meter" [style.width.%]="clickRate || 0"></div>
            </div>
            <div class="report-title">{{totalClicks}} clicks • {{uniqueClicks}} unique</div>
          </ion-card>
        </ion-col>
        <ion-col size-md="6" size="12" *ngIf="isSlack">
          <ion-card class="outline-card report-card">
            <div class="report-title">Reply rate</div>
            <span class="report-stat">{{replyRate ? replyRate + '%' : '•••'}}</span>
            <div class="onboarding-progress-bar">
              <div class="meter" [style.width.%]="replyRate || 0"></div>
            </div>
            <div class="report-title">{{conversations?.length}} replies</div>
          </ion-card>
        </ion-col>
        <ion-col size="12" *ngIf="isSlack">
          <h3 class="column-header">Channels ({{ data.length }})</h3>
        </ion-col>
        <ion-col size="12">
          <ion-card class="outline-card">
            <ngx-datatable
              class="material fullscreen"
              [columnMode]="ColumnMode.flex"
              [limit]="100"
              [headerHeight]="50"
              [footerHeight]="50"
              [rowHeight]="50"
              [scrollbarH]="true"
              [rows]="data"
              [loadingIndicator]="isLoading"
            >
              <ngx-datatable-column name="Name" prop="user" [flexGrow]="2" [sortable]="true" *ngIf="isEmail">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                  {{getUserName(value)}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="isEmail" name="Email" prop="email" [flexGrow]="3" [sortable]="true">
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="isEmail" name="Opens" prop="opens" [flexGrow]="1" [sortable]="true">
              </ngx-datatable-column>
              <ngx-datatable-column
                *ngIf="isSlack"
                name="Recipient"
                prop="customerName"
                [flexGrow]="3"
                [sortable]="true"
              >
              </ngx-datatable-column>
              <ngx-datatable-column *ngIf="isSlack" name="Channel" prop="channelName" [flexGrow]="3" [sortable]="true">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                  {{getChannelName(row)}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Clicks" prop="clicks" [flexGrow]="1" [sortable]="true"></ngx-datatable-column>
            </ngx-datatable>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <div *ngIf="tab === 'clicksByUrl'" style="padding: 15px">
    <div *ngFor="let url of clicksByUrlData">
      <code style="line-break: anywhere; line-height: 3; font-size: 0.85rem">
        <ion-icon name="link-outline"></ion-icon>
        {{url.url}}
      </code>
      <ion-card class="outline-card" style="margin: 0">
        <ngx-datatable
          class="material fullscreen"
          [columnMode]="ColumnMode.flex"
          [limit]="100"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="50"
          [scrollbarH]="true"
          [rows]="url.items"
          [loadingIndicator]="isLoading"
        >
          <ngx-datatable-column name="Name" prop="user" [flexGrow]="2" [sortable]="true">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              {{getUserName(value)}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="isEmail" name="Email" prop="email" [flexGrow]="3" [sortable]="true">
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="isSlack" name="Channel" prop="channelName" [flexGrow]="3" [sortable]="true">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              {{getChannelName(row)}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Clicks" prop="clicks" [flexGrow]="1" [sortable]="true"></ngx-datatable-column>
        </ngx-datatable>
      </ion-card>
    </div>
    <div *ngIf="!clicksByUrlData || !clicksByUrlData.length">
      <div class="empty-state-container">
        <span class="placeholder-emoji">
          <ion-icon name="telescope-outline"></ion-icon>
        </span>
        <h1 class="placeholder-title">No clicks</h1>
        <p class="placeholder-subtitle">Any clicks on URLs will appear here</p>
      </div>
    </div>
  </div>

  <div *ngIf="tab === 'conversations'" style="padding: 10px">
    <ion-card *ngFor="let conversation of conversations" class="outline-card">
      <ion-card-content>
        <p style="margin: 2px 0; color: var(--ion-color-step-600)">
          {{ conversation.customer.name }} in #{{ conversation.customer.slackChannel.name }}
        </p>
        <a
          routerLink="/dashboard/inbox/all/conversations/{{conversation.id}}"
          target="_blank"
          class="row-title row-link"
        >
          [#{{conversation.friendlyId}}] {{conversation.title }}
        </a>
        <br />
        <br />
        <ion-chip *ngFor="let reaction of conversation.initialMessage.reactions">
          <ng-container *appVar="getReactionEmoji(reaction.name) as reactionEmoji">
            <span *ngIf="!reactionEmoji.startsWith('https://')" style="font-size: 1.5rem">{{ reactionEmoji }}</span>
            <span
              *ngIf="reactionEmoji.startsWith('https://')"
              [ngStyle]="{ 'background-image': 'url(' + reactionEmoji + ')' }"
              style="
                background-repeat: no-repeat;
                background-size: cover;
                background-color: #efefff;
                background-position: center;
                width: 18px;
                height: 18px;
              "
            ></span>
          </ng-container>
          <span *ngIf="reaction.count > 1" style="font-size: 0.75rem; margin-left: 2px"> {{ reaction.count }} </span>
        </ion-chip>
      </ion-card-content>
    </ion-card>
    <div *ngIf="!conversations.length">
      <div class="empty-state-container">
        <span class="placeholder-emoji">
          <ion-icon name="telescope-outline"></ion-icon>
        </span>
        <h1 class="placeholder-title">No conversations</h1>
        <p class="placeholder-subtitle">No one has started any conversations from this message</p>
      </div>
    </div>
  </div>
</ion-content>
